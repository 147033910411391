
import Header from './static/header'
import Carousels from './home/carousel'
import Socials from './static/socials'
import About from './static/about'
import Maps from './static/maps'
import Game from './static/game'
import Tokenomics from './static/tokenomics'
import Team from './static/team'
import Guide from './static/guide'
import Community from './static/community'
import Characters from './static/characters'
import SocialMedia from './static/socialmedia'
import Footer from './static/footer'

import { Helmet } from "react-helmet";


function Landing() {
  return (
    <>

    <Helmet>
      <title>Shiba Universe - Game Multiplayer Blockchain P2E</title>
    </Helmet>

    <Header/>
    <Carousels />
    <Socials />
    <About />
    <Characters />
    <Maps />
    <Game/>
    <Tokenomics />
    <Team />
    <Guide />
    <Community />
    <SocialMedia />
    <Footer/>

    </>
  );
}

export default Landing;
