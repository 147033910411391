import {
    BsFacebook, BsTwitter, BsTelegram,
    BsDiscord, BsInstagram, BsTwitch,
    BsReddit, BsYoutube
} from "react-icons/bs";




function Socials() {

    return (<>

        <section className='social-networks'>
            <div className='container mx-auto max-w-screen-xl flex justify-between pt-5 pb-5 list-social'>
               

                <article className='social-networks-article'>
                    <a href='https://x.com/shibusolana' target='_blank'>
                        <BsTwitter />
                        Twitter
                    </a>
                </article>

                <article className='social-networks-article'>
                    <a href='https://t.me/shibusolana' target='_blank'>
                        <BsTelegram />
                        Telegram
                    </a>
                </article>

            </div>

        </section>

    </>)

}

export default Socials;
