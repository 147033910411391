import {
    BrowserRouter as Router,
    Switch,
    Routes,
    Route,
    Link,
    useParams
  } from "react-router-dom";
  
  import Landing from './Landing';
  import Access from './Landing/access';
  
  
  function App(): JSX.Element {
      return (
        <Routes>
          <Route path='/' element={<Landing/>} />
          <Route path='/play-game' element={<Access/>} />
        </Routes>
      )
  }
  
  export default App
  