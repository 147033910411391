import React, { useState, useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";


const handleDragStart = (e) => e.preventDefault();

const items = [
    <img src="/img/scene1.png" onDragStart={handleDragStart} />,
    <img src="/img/scene2.png" onDragStart={handleDragStart} />,
    <img src="/img/scene3.png" onDragStart={handleDragStart} />,
    <img src="/img/scene4.png" onDragStart={handleDragStart} />,
];

const thumbItems = (items, [setThumbIndex, setThumbAnimation]) => {
    return items.map((item, i) => (
        <div className="thumb" onClick={() => (setThumbIndex(i), setThumbAnimation(true))}>
            {item}
        </div>
    ));
};

function Maps() {
    const [mainIndex, setMainIndex] = useState(0);
    const [mainAnimation, setMainAnimation] = useState(false);
    const [thumbIndex, setThumbIndex] = useState(0);
    const [thumbAnimation, setThumbAnimation] = useState(false);
    const [thumbs] = useState(thumbItems(items, [setThumbIndex, setThumbAnimation]));



    const slideNext = () => {
        if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
            setThumbAnimation(true);
            setThumbIndex(thumbIndex + 1);
        }
    };

    const slidePrev = () => {
        if (!thumbAnimation && thumbIndex > 0) {
            setThumbAnimation(true);
            setThumbIndex(thumbIndex - 1);
        }
    };

    const syncMainBeforeChange = (e) => {
        setMainAnimation(true);
    };

    const syncMainAfterChange = (e) => {
        setMainAnimation(false);

        if (e.type === 'action') {
            setThumbIndex(e.item);
            setThumbAnimation(false);
        } else {
            setMainIndex(thumbIndex);
        }
    };

    const syncThumbs = (e) => {
        setThumbIndex(e.item);
        setThumbAnimation(false);

        if (!mainAnimation) {
            setMainIndex(e.item);
        }
    };



    return (<>

        <section className='about-shibu pt-20 pb-20 maps-shibu' id='maps'>

            <div className='block-image-separate-maps'>
                <img src='/img/visorshib.png' />
            </div>

            <div className='container mx-auto max-w-screen-xl'>

                <header className='about-shibu-header text-center'>
                    <h2><b>3</b> MAPS</h2>
                    <p>There are 30 universes where the tournaments will take place, the maps have sounds and special effects</p>
                </header>

                <section className='maps-shibu-list-mobile'>
                    <Carousel autoPlay>
                        <div className='mobile-item-1'>
                            <img src='/img/maps-mobile1.jpg' />
                        </div>
                        <div className='mobile-item-2'>
                            <img src='/img/maps-mobile2.jpg' />
                        </div>
                        <div className='mobile-item-3'>
                            <img src='/img/maps-mobile3.jpg' />
                        </div>
                        <div className='mobile-item-4'>
                            <img src='/img/maps-mobile4.jpg' />
                        </div>
                    </Carousel>
                </section>

                <section className='maps-shibu-list pt-10'>
                    <AliceCarousel
                        animationType="fadeout"
                        animationDuration={800}
                        infinite
                        activeIndex={thumbIndex}
                        autoWidth
                        disableDotsControls
                        disableButtonsControls
                        items={thumbs}
                        mouseTracking={true}
                        onSlideChanged={syncThumbs}
                        touchTracking={!mainAnimation}
                        controlsStrategy="alternate"
                        responsive={true}
                    />
                    <div className="btn-prev" onClick={slidePrev}>
                        <HiChevronLeft />
                    </div>
                    <div className="btn-next" onClick={slideNext}>
                        <HiChevronRight />
                    </div>
                </section>

            </div>

        </section>

    </>)

}

export default Maps;