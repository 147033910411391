import React, { useState, useEffect } from 'react';


function About(){

    let obj = {
        history: `
        <div class='desc-details'>
        <b>ShibaUniverse</b> is a festival held every 13 years by the <b>Wizard Orum</b> in a multiverse where the participants are humans and their pets who compete to achieve a legacy in history.
        </div>
         <div class='desc-details'>
         Succeeding in the ShibaUniverse tournament will bring recognition throughout the multiverse of the participants and citizens.
         </div>
         
         <div class='desc-details'>
         While the human beings continued with their daily lives, the <b>Orum Wizard</b> teleported all the people who have pets in their homes to the multiverse where this competition will take place.
         </div>

         <div class='desc-details'>
         The pets and the people are dismayed by what happened, it is where orum is present to announce the start of the event, many begin to understand what this new territory is about and begin to organize competition teams to return to their normal lives.
         </div>

         <div class='desc-details'>
         this is how this new adventure begins
         </div>

         `
    }

    const [details, setDetails] = useState(obj.history);


    function ShowAboutDetails(type){
        var details;

        switch (type) {
            case 1:

                details = obj.history;
                
                
                break;
            case 2:

                details = `<div class='desc-details'>Our mission is to provide you with entertainment, security and trust, through the integration of decentralized blockchains and to expand our community in the cryptocurrency sector, based on the experience and strategic partners to achieve our goals.</div>`;

                break;

            case 3:

                details = '<div class="desc-details">Our vision is to expand the community in the different blockchains and increase our owners through our shiba universe product, with a firm commitment to decentralization and the development of new features in the world of shiba universe and promoting alliances and relationships with different similar projects. .</div>';

                break;
        
            default:


                break;
        }
        
        
         

        setDetails(details);
    }

    

    return (<>

        <section className='about-shibu pt-20 pb-20' id='abouts'>

            <div className='block-image-separate'>
                <img src='/img/flag.svg' />
            </div>
           
            <div className='container mx-auto max-w-screen-xl'>

                <div className='about-shibu-history' onClick={ () => ShowAboutDetails(1) }>
                History
                </div>

                <div className='about-shibu-mission' onClick={ () => ShowAboutDetails(2) }>
                Mission
                </div>

                <div className='about-shibu-vission' onClick={ () => ShowAboutDetails(3) }>
                Vission
                </div>

                <header className='about-shibu-header text-center'>
                    <h2><b>1</b> ABOUT</h2>
                    <p>We are a project created by professionals in cryptocurrencies and video games</p>
                </header>

                <section className='about-shibu-description'>
                    <div className='is-image'>
                        <img src='/img/orum-black.png' width='500'/>
                        
                    </div>
                    <div className='is-description' dangerouslySetInnerHTML={{__html: details}}>
                    </div>
                </section>
            
            </div>

            

        </section>

    </>)

}

export default About;