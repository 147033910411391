import AnchorLink from 'react-anchor-link-smooth-scroll-v2'
import { slide as Menu } from 'react-burger-menu'


function Header(){


    return(<> 

    <section className='warning-buy'>
            <h3>Important: SHIBU available on the <a className='ethereum' href='#' target='_blank'><b> Solana</b></a> 
            Check our official links de <a className='uniswap' href='https://pump.fun/3aagGxSPaZubvd8j95j5t8gXJfjgLZmw4kdL97bRpump' target='_blank'>PUMP.FUN</a> 
            </h3>
    </section>


    <section className='header-mobile'>
        <article>
            <a className='title' href='/'>
                    <img src='/img/shibauniverse.png' width='180'/>
            </a>
        </article>
        <Menu right pageWrapId={ "page-wrap" } >
            <AnchorLink id="about" className="menu-item" href="#abouts">About</AnchorLink>
            <AnchorLink id="character" className="menu-item" href="#characters">Characters</AnchorLink>
            <AnchorLink id="contact" className="menu-item" href="#maps">Maps</AnchorLink>
            <AnchorLink id="contact" className="menu-item" href="#game">Game</AnchorLink>
            <AnchorLink id="contact" className="menu-item" href="#tokenomics">Tokenomics</AnchorLink>
            <AnchorLink id="contact" className="menu-item" href="#team">NFT</AnchorLink>
            <AnchorLink id="contact" className="menu-item" href="#guide">Guide</AnchorLink>
            <AnchorLink id="contact" className="menu-item" href="#community">Community</AnchorLink>
        </Menu>
    </section>
    
    <header className="header pt-3 pb-3">

    

        <div className="header-container container mx-auto max-w-screen-xl">
            <section className='flex justify-between w-full'>
                <a className='title' href='/'>
                    <img src='/img/shibauniverse.png' width='180'/>
                </a>
                <nav className='header-nav'>
                    <AnchorLink className='header-nav-list' href='#abouts'>
                        About
                    </AnchorLink>
                    <AnchorLink className='header-nav-list' href='#characters'>
                        Characters
                    </AnchorLink>
                    <AnchorLink className='header-nav-list' href='#maps'>
                        Maps
                    </AnchorLink>
                    <AnchorLink className='header-nav-list' href='#game'>
                        Game
                    </AnchorLink>
                    <AnchorLink className='header-nav-list' href='#tokenomics'>
                        Tokenomics
                    </AnchorLink>
                    <AnchorLink className='header-nav-list' href='#team'>
                        Nft
                    </AnchorLink>
                    <AnchorLink className='header-nav-list' href='#guide'>
                        Guide
                    </AnchorLink>
                    <AnchorLink className='header-nav-list' href='#community'>
                        Community
                    </AnchorLink>

                    <a className='header-nav-list play-game' href='https://pump.fun/3aagGxSPaZubvd8j95j5t8gXJfjgLZmw4kdL97bRpump' target='_blank'>
                       BUY $SHIBU
                    </a>
                </nav>
            </section>
        </div>

    </header>
    
    </>)


}

export default Header;
