import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';


var labels = [
    'Earth',
    'Mars',
    'Saturn',
    'Jupiter'
];


export const data = {

    labels: [],
    datasets: [
        {
            type: 'doughnut',
            labels: ['a'],
            hoverBorderColor: 'white',
            data: [100],
            backgroundColor: [
                '#f95c08',
                '#fdaf32',
                '#2cb56f',
            ],
            hoverBackgroundColor: ["#c64500", "#d28c1b", "#1d8751"]

        },
    ],
    plugins: {
        labels: {
            render: "percentage",
            fontColor: ["green", "white", "red"],
            precision: 2
        }
    },
    text: "23%"
};

function Tokenomics() {

    ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

    return (<>

        <section className='about-shibu pt-20 pb-20 tokenomics-shibu' id='tokenomics'>

            <div className='block-image-separate-maps'>
                <img src='/img/wing.png' />
            </div>

            <div className='container mx-auto max-w-screen-xl'>

                <header className='about-shibu-header text-center'>
                    <h2><b>5</b> TOKENOMICS</h2>
                    <p>Distribution and participation of shibu tokens</p>
                </header>

                <section className='tokenomics-container pt-10 flex justify-between items-center'>
                    <div className='tokenomics-pie-chart'>
                        <Pie data={data} className='tokenomics-charts' options={{
                            cutoutPercentage: 75,
                            legend: {
                                display: false
                            },
                            plugins: {
                                doughnutlabel: {
                                    labels: [
                                        {
                                            font: {
                                                size: "40"
                                            },
                                            color: "black"
                                        },
                                        {
                                            text: "Due ≤ 60 Days",
                                            font: {
                                                size: "25"
                                            },
                                            color: "grey"
                                        }
                                    ]
                                },
                                ChartDataLabels,
                                datalabels: {
                                    // display: false,
                                    color: "black",
                                    font: {
                                        size: 14,
                                        weight: "bold"
                                    }
                                }
                            }
                        }} />
                    </div>
                    <div className='tokenomics-pie-info'>
                        <h2 className='pb-5'>Smart contracts are very necessary tools for our game, security is paramount in shibu token, the distribution of the tokens is done in several groups that contain an essential function in the project.</h2>
                        <section className='tokenomics-pie-list'>
                            <h3>Pool Dex 100%</h3>
                        </section>
                    </div>
                </section>

              

            </div>

        </section>

    </>)

}

export default Tokenomics;
