import React, { useState, useEffect } from 'react';


function Community(){
    


    return (<>

        <section className='about-shibu pt-20 pb-20 community-shibu' id='community'>
           
            <div className='container mx-auto max-w-screen-xl'>

                <header className='about-shibu-header text-center'>
                    <h2><b>8</b> COMMUNITY</h2>
                    <p>Information and news about our ecosystem</p>
                </header>

                <div className='flex pt-10 justify-between'>
                    <div className='container mx-auto max-w-screen-xl flex justify-between p-10 item-community'>
                        <a className='community-article' href='#' target='_blank'>
                            <img src='/img/vifby.png' />
                            
                        </a>
                    </div>

                    <div className='container mx-auto max-w-screen-xl flex justify-between p-10 item-community'>
                        <a className='community-article' href='#' target='_blank'>
                            <img src='/img/shibauniversedev.png' />
                           
                        </a>
                    </div>

                    <div className='container mx-auto max-w-screen-xl flex justify-between p-10 item-community'>
                        <a className='community-article' href='#' target='_blank'>
                            <img src='/img/shibainu.png' />
                           
                        </a>
                    </div>
                    
                </div>

                

            </div>

        </section>

    </>)

}

export default Community;
