import React, { useState, useEffect } from 'react';


function Team(){
    


    return (<>

        <section className='about-shibu pt-20 pb-20 teams-shibu' id='team'>

            <div className='block-image-separate-tokenomics'>
                <img src='/img/shield.png' />
            </div>
           
            <div className='container mx-auto max-w-screen-xl'>

                <header className='about-shibu-header text-center'>
                    <h2><b>6</b> NFT</h2>
                    <p>NFTs are developed and illustrated exclusively by community professionals, 300 exclusive NFTs will be created</p>
                </header>


                <div className='grid grid-cols-0 gap-y-14 md:gap-y-0 md:grid-cols-3 md:gap-x-8 pt-10 list-teams '>
                    <div className='container mx-auto max-w-screen-xl flex justify-center pt-10 pb-10'>
                        <a href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/92901620433843133307500093410936491546942225193855142111153994461362117411816' target='_blank' className='teams-shibu-article'>
                            <img src='https://lh3.googleusercontent.com/l7AbrZBIPhQlPZYwBSz89Fav224Xy5D2tOuLLPa6WbG19G2yLP4dP89T-3FR_RfYYhiymmAqq79MeaEnANKCmtyHTAkBJnr1UZ_G8A=w600' width='230' />
                            <p>ShibaUniverse #1</p>
                            <span className='text-center block pb-2'>NFT reward holders</span>
                        </a>
                    </div>

                    <div className='container mx-auto max-w-screen-xl flex justify-center pt-10 pb-10'>
                        <a href='https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/92901620433843133307500093410936491546942225193855142111153994462461629038842' target='_blank' className='teams-shibu-article'>
                            <img src='https://lh3.googleusercontent.com/NXjfGxWv9G6cNPUv_8kdh__0jkSVuXJTgpnui7lEk7Cz_RKoIoG0k4lBoST04xY-TUgDn0iTmmZvzFyYqtlQb2qcyJAry9M6MEj60Q=w600' width='230' />
                            <p>ShibaUniverse #2</p>
                            <span className='text-center block pb-2'>NFT reward holders</span>
                        </a>
                    </div>

                    <div className='container mx-auto max-w-screen-xl flex justify-center pt-10 pb-10'>
                        <a href='https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/92901620433843133307500093410936491546942225193855142111153994463561140666518' target='_blank' className='teams-shibu-article'>
                            <img src='https://lh3.googleusercontent.com/Qt-NqR0aeFVHWAjEfNLw1l3HTywk8RU_AwhTS6-ZJVCW-YdnoxO-X5-DC3Zsb69lDu-_2i2NHrpAzCac8baiG1vcD6P9EafkG1t0=w600' width='230' />
                            <p>ShibaUniverse #3</p>
                            <span className='text-center block pb-2'>NFT reward holders</span>
                        </a>
                    </div>
                    
                </div>
               
            
            </div>

        </section>

    </>)

}

export default Team;