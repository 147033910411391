import { BsFacebook, BsTwitter, BsTelegram,
    BsDiscord, BsInstagram, BsTwitch,
    BsReddit, BsYoutube } from "react-icons/bs";

function Footer(){

    return(<> 
    
    <section className='footer-landing'>

        <div className='container mx-auto max-w-screen-xl flex justify-between flex-wrap'>

            <div className='flex justify-between footer-left'>
                <article className='footer-landing-list'>
                    <label>Help</label>
                    <ul className='list-ul'>
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='#' target='_blank'>
                                Docs
                            </a>
                        </li>
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='#' target='_blank'>
                                Forum
                            </a>
                        </li>
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='mailto:info@shibauniverse.com'>
                                Contact
                            </a>
                        </li>
                    </ul>
                </article>

                <article className='footer-landing-list'>
                    <label>INFORMATION</label>
                    <ul className='list-ul'>
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='#' target='_blank'>
                                White Paper
                            </a>
                        </li>
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='#'>
                                Tutorials
                            </a>
                        </li>
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='#' target='_blank'>
                                News
                            </a>
                        </li>
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='#'>
                                Careers
                            </a>
                        </li>
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='#'>
                                Education
                            </a>
                        </li>
                    </ul>
                </article>

                <article className='footer-landing-list'>
                    <label>SHIBU GAME</label>
                    <ul className='list-ul'>
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='#' target='_blank' title='etherscan'>
                                Solscan
                            </a>
                        </li>
                        
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='#' target='_blank' title='uniswap'>
                                Buy Token 
                            </a>
                        </li>
                       
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='#' target='_blank' title='dextools'>
                                Chart Price 
                            </a>
                        </li>
                        <li className='list-ul-li'>
                            <a className='list-ul-li-a' href='#'>
                                Coinmarketcap
                            </a>
                        </li>
                    </ul>
                </article>
            </div>

            <div className='footer-right'>
                    <article className='footer-landing-list'>
                        <label>Our community</label>
                        <ul className='list-ul-social'>
                           

                            <li className='list-ul-social-li'>
                                <a className='list-ul-social-li-a' href='https://x.com/shibusolana' target='_blank'>
                                    <BsTwitter/>
                                </a>
                            </li>

                            <li className='list-ul-social-li'>
                                <a className='list-ul-social-li-a' href='https://t.me/shibusolana' target='_blank'>
                                    <BsTelegram/>
                                </a>
                            </li>

                         
                            
                           
                        </ul>
                    </article>

                    <article className='footer-landing-list'>
                        <ul className='list-ul'>
                            <li className='list-ul-li'>
                                <a className='list-ul-li-a' href='#'>
                                Privacy Policy
                                </a>
                            </li>

                            <li className='list-ul-li'>
                                <a className='list-ul-li-a' href='#'>
                                Terms and Conditions
                                </a>
                            </li>
                          
                        </ul>
                    </article>
            </div>


        </div>

    </section>

    <footer className='footer-static-copyright'>
        ©2024 Shiba Universe LTD. All rights reserved.
    </footer>
    
    </>)

}

export default Footer;
