import React from 'react';

function Guide() {
    return (
        <>
            <section className='about-shibu pt-20 pb-20 guide-shibu' id='guide'>
                <div className='block-image-separate-guide'>
                    <img src='/img/booking.png' alt='Guide' />
                </div>

                <div className='container mx-auto max-w-screen-xl'>
                    <header className='about-shibu-header text-center'>
                        <h2><b>7</b> GUIDE</h2>
                        <p>Steps to buy Shibu tokens on Solana</p>
                    </header>

                    <section className='guide-list pt-10'>
                        <article className='guide-list-article py-5'>
                            <h3>Phantom Wallet</h3>
                            <p>Phantom is a browser extension and mobile wallet that allows you to manage your Solana tokens and NFTs. It provides a simple and secure way to interact with Solana-based assets and applications.</p>
                        </article>

                        <article className='guide-list-article py-5'>
                            <h3>Get SOL (Solana)</h3>
                            <p>The most common way to obtain SOL is to buy it on an exchange with fiat currency (US dollars, Euro, GBP, Yen, etc). There are many exchanges to choose from—research to find one that suits you best.</p>
                        </article>

                        <article className='guide-list-article py-5'>
                            <h3>Use Raydium</h3>
                            <p>Raydium is a decentralized exchange and automated market maker (AMM) built on the Solana blockchain. It allows you to swap, earn, and trade tokens with high speed and low fees.</p>
                        </article>

                        <article className='guide-list-article py-5'>
                            <h3>Swap SOL for SHIBU</h3>
                            <p>Select the token you wish to trade (SOL) and the token you wish to receive (SHIBU). Use Raydium's interface to search for SHIBU by its token name or address, then complete the swap.</p>
                        </article>
                    </section>

                   
                </div>
            </section>
        </>
    );
}

export default Guide;
