import React, { useState, useEffect } from 'react';


function Game(){
    
    return (<>

        <section className='about-shibu pt-20 pb-20 community-shibu' id='game'>

            <div className='block-image-separate-game'>
                <img src='/img/tc.png' />
            </div>
           
            <div className='container mx-auto max-w-screen-xl'>

                <header className='about-shibu-header text-center'>
                    <h2><b>4</b> GAME</h2>
                    <p>Shiba Universe is a real-time multiplayer game</p>
                </header>

                <div className='flex pt-10 justify-between list-game'>
                    <div className='container mx-auto max-w-screen-xl flex justify-between p-10'>
                        <a className='game-article-screenshot text-center' href='#'>
                            <img src='/img/screen1.jpg' width='390'/>
                            <p>Group and individual tournaments</p>
                        </a>

                        <a className='game-article-screenshot text-center' href='/img/screen2.jpg' target='_blank'>
                            <img src='/img/screen2.jpg' width='390'/>
                            <p>Multiple characters and universes</p>
                        </a>

                        <a className='game-article-screenshot text-center' href='/img/screen3.jpg' target='_blank'>
                            <img src='/img/screen3.jpg' width='390'/>
                            <p>Play and win shibu tokens</p>
                        </a>
                        
                    </div>
                </div>

                <div className='flex pt-10 justify-between list-game'>
                    <div className='container mx-auto max-w-screen-xl flex justify-between p-10'>
                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl1.png' width='100'/>
                            <p>Level 1</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl2.png' width='100'/>
                            <p>Level 2</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl3.png' width='100'/>
                            <p>Level 3</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl4.png' width='100'/>
                            <p>Level 4</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl5.png' width='100'/>
                            <p>Level 5</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl6.png' width='100'/>
                            <p>Level 6</p>
                        </a>
                        
                    </div>
                </div>
                <div className='flex pt-10 justify-between list-game'>
                    <div className='container mx-auto max-w-screen-xl flex justify-between p-10'>
                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl7.png' width='100'/>
                            <p>Level 7</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl8.png' width='100'/>
                            <p>Level 8</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl9.png' width='100'/>
                            <p>Level 9</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl10.png' width='100'/>
                            <p>Level 10</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl11.png' width='100'/>
                            <p>Level 11</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl12.png' width='100'/>
                            <p>Level 12</p>
                        </a>
                        
                    </div>
                </div>

                <div className='flex pt-10 justify-between list-game'>
                    <div className='container mx-auto max-w-screen-xl flex justify-between p-10'>
                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl13.png' width='100'/>
                            <p>Level 13</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl14.png' width='100'/>
                            <p>Level 14</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl15.png' width='100'/>
                            <p>Level 15</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl16.png' width='100'/>
                            <p>Level 16</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl17.png' width='100'/>
                            <p>Level 17</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl18.png' width='100'/>
                            <p>Level 18</p>
                        </a>
                        
                    </div>
                </div>

                <div className='flex pt-10 justify-between list-game'>
                    <div className='container mx-auto max-w-screen-xl flex justify-between p-10'>
                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl19.png' width='100'/>
                            <p>Level 19</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl20.png' width='100'/>
                            <p>Level 20</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl21.png' width='100'/>
                            <p>Level 21</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl22.png' width='100'/>
                            <p>Level 22</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl23.png' width='100'/>
                            <p>Level 23</p>
                        </a>

                        <a className='game-article-screenshot-lvl text-center' href='#'>
                            <img src='/img/lvl24.png' width='100'/>
                            <p>Level 24</p>
                        </a>
                        
                    </div>
                </div>
            
            </div>

        </section>

    </>)

}

export default Game;