import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactFlagsSelect from 'react-flags-select';
import { TailSpin } from  'react-loader-spinner'
import { VscTriangleLeft } from "react-icons/vsc";
import 'react-tabs/style/react-tabs.css';
import './access.css';


function Access(){

    const [selected, setSelected] = useState('');
    var loaderHidde = true;

    setTimeout(() => {
        document.getElementById('loaderTab').style.display = 'none';
    }, 1500)

    const setTabs = (index) => {
        var timer = (index) ? 1500 : 1500;
        document.getElementById('loaderTab').style.display = 'block';
        setTimeout(() => {
            document.getElementById('loaderTab').style.display = 'none';
        }, timer)
    }

    return (<> 

        <a className='prev-game' href='/'>
            <VscTriangleLeft/>
            Prev
        </a>

        <section className='play-game'>

            <div className='play-game-container'>

                <section className='account-access mx-auto px-4 md:w-full md:max-w-md'>

                        <header className='text-center mb-10 mt-12 pt-5'>
                            <h1>
                                <a href='/'>
                                    <img src='/img/shibauniverse.png' className='m-auto'/>
                                </a>
                            </h1>
                        </header>

                        <Tabs defaultIndex={0} onSelect={index => setTabs(index)}>
                            <TabList>
                                <Tab>SIGN IN</Tab>
                                <Tab>CREATE ACCOUNT</Tab>
                            </TabList>

                            <TabPanel>
                                
                                <form action='' method='post' className='account-form form_input mt-5'>
                                    <div className='input-container mb-4'>
                                        <span>Enter your email account</span>
                                        <input type='email' placeholder='Enter email' />
                                    </div>

                                    <div className='input-container mb-2'>
                                        <span>Enter your password account</span>
                                        <input type='password' placeholder='Enter password' />
                                    </div>

                                    <div className='input-container mb-2'>
                                        <span className='tag-info-data'>I agree to the SHIBU <a href='#'>Terms of Use.</a> To understand how we process your information, please see our <a href='#'>Privacy Policy</a>.</span>
                                    </div>

                                    <div className='input-container pt-2'>
                                        <input type='submit' value='CONTINUE' disabled={true} />
                                    </div>
                                </form>
                            </TabPanel>
                            <TabPanel>
                                <form className='createaccount-form form_input'>
                                    <div className='input-container mb-4'>
                                        <span>Enter your username</span>
                                        <input type='text' placeholder='Username' />
                                    </div>

                                    <div className='input-container mb-4'>
                                        <span>Enter your email</span>
                                        <input type='email' placeholder='Enter Email' />
                                    </div>

                                    <div className='input-container mb-4'>
                                        <span>Enter your password</span>
                                        <input type='password' placeholder='Enter password' />
                                    </div>
                                    <div className='input-container mb-4'>
                                        <span>Enter your country</span>
                                        <ReactFlagsSelect
                                            selected={selected}
                                            onSelect={code => setSelected(code)}
                                            placeholder="Select Country"
                                            searchable
                                        />
                                    </div>

                                    <div className='input-container mb-4'>
                                        <span>Enter your address</span>
                                        <input type='text' placeholder='Enter address ethereum' />
                                    </div>

                                    <div className='input-container mb-2'>
                                        <span className='tag-info-data'>I agree to the SHIBU <a href='#'>Terms of Use.</a> To understand how we process your information, please see our <a href='#'>Privacy Policy</a>.</span>
                                    </div>

                                    <div className='input-container pt-2'>
                                        <input type='submit' value='CREATE ACCOUNT' />
                                    </div>
                                </form>
                            </TabPanel>

                            <div id='loaderTab' className='loaderTab'>
                                    <TailSpin
                                        height="59"
                                        width="50"
                                        color='white'
                                        visible={loaderHidde}
                                    />
                                </div>
                        </Tabs>

                        

                </section>

                

            </div>

        </section>
        <footer className='footer-static-copyright'>
                ©2022 Shiba Universe LTD. All rights reserved.
        </footer>
    
    </>)

}

export default Access;