function Characters(){

    return (<>


<       section className='about-shibu pt-20 pb-20 characters-shibu' id='characters'>
           
           <div className='container mx-auto max-w-screen-xl'>

               <header className='about-shibu-header text-center'>
                   <h2><b>2</b> CHARACTERS</h2>
                   <p>Characters from the shiba multiverse, free options and available through the game platform</p>
               </header>

               <div className='flex pt-10 list-characters'>
                    <div className='container mx-auto max-w-screen-xl flex justify-between pt-10 pb-10'>
                        <article className='characters-shibu-article'>
                            <img src='/img/caryus.png' width='200' />
                            <p>Caryus</p>
                        </article>
                    </div>

                    <div className='container mx-auto max-w-screen-xl flex justify-between pt-10 pb-10'>
                        <article className='characters-shibu-article'>
                            <img src='/img/layon.png' width='200' />
                            <p>Layon</p>
                        </article>
                    </div>

                    <div className='container mx-auto max-w-screen-xl flex justify-between pt-10 pb-10'>
                        <article className='characters-shibu-article'>
                            <img src='/img/akara.png' width='200' />
                            <p>Akara</p>
                        </article>
                    </div>

                    <div className='container mx-auto max-w-screen-xl flex justify-between pt-10 pb-10'>
                        <article className='characters-shibu-article'>
                            <img src='/img/kamu.png' width='200' />
                            <p>Kamu</p>
                        </article>
                    </div>


                    <div className='container mx-auto max-w-screen-xl flex justify-between pt-10 pb-10'>
                        <article className='characters-shibu-article'>
                            <img src='/img/yimshy.png' width='200' />
                            <p>Yim</p>
                        </article>
                    </div>


                    
                </div>

                <div className='flex pt-10 list-characters'>
                    <div className='container mx-auto max-w-screen-xl flex justify-between pt-10 pb-10'>
                        <article className='characters-shibu-article'>
                            <img src='/img/shuma.png' width='200' />
                            <p>Shuma</p>
                        </article>
                    </div>

                    <div className='container mx-auto max-w-screen-xl flex justify-between pt-10 pb-10'>
                        <article className='characters-shibu-article'>
                            <img src='/img/flopy.png' width='200' />
                            <p>Flopy</p>
                        </article>
                    </div>

                    <div className='container mx-auto max-w-screen-xl flex justify-between pt-10 pb-10'>
                        <article className='characters-shibu-article'>
                            <img src='/img/brika.png' width='200' />
                            <p>Brika</p>
                        </article>
                    </div>

                    <div className='container mx-auto max-w-screen-xl flex justify-between pt-10 pb-10'>
                        <article className='characters-shibu-article'>
                            <img src='/img/splym.png' width='200' />
                            <p>Splym</p>
                        </article>
                    </div>


                    <div className='container mx-auto max-w-screen-xl flex justify-between pt-10 pb-10'>
                        <article className='characters-shibu-article'>
                            <img src='/img/noryum.png' width='200' />
                            <p>Noryum</p>
                        </article>
                    </div>


                    
                </div>
           
           </div>

       </section>


    </>)

}

export default Characters;