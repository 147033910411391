function SocialMedia(){

    return(
        <>
        
        <section className='social-media'>

            <div className='container mx-auto max-w-screen-xl flex justify-between flex-wrap'>

                <header className='media-shibu-header text-center'>
                    <h3>PARTNERS & INTEGRATIONS</h3>
                    <p>Strategic partners who trust us</p>
                </header>

                <section className='media-list-icons flex justify-between flex-wrap pb-20'>

                    <article className='icons-article'>
                        <a href='#' target='_blank' title='coinmarketcap'>
                            <img src='/img/vifcap.png' width='150' />
                        </a>                    
                    </article>

                    <article className='icons-article'>
                        <a href='#' target='_blank' title='coinmarketcap'>
                            <img src='/img/cmc.png' width='150' />
                        </a>
                    </article>

                   


                    <article className='icons-article'>
                        <a href='#' target='_blank' title='coingecko'>
                            <img src='/img/coingecko.png' width='150' />
                        </a>
                    </article>

                    <article className='icons-article'>
                        <a href='#' target='_blank' title='coingecko'>
                            <img src='/img/cryptologo.png' width='150' />
                        </a>
                    </article>

                    

                </section>



            </div>

        </section>
        
        </>
    )

}

export default SocialMedia;
