import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const handleDragStart = (e) => e.preventDefault();

const items = [
  <img src="/img/banner-shiba-1.jpg" onDragStart={handleDragStart} />,
  <img src="/img/banner-shiba-2.jpg" onDragStart={handleDragStart} />,
  <img src="/img/banner-shiba-3.jpg" onDragStart={handleDragStart} />,
];

function Carousels() {

  return (<>

    <Carousel autoPlay>
      <div className='slider-item-1'>
      </div>
      <div className='slider-item-2'>
      </div>
      <div className='slider-item-3'>
      </div>
    </Carousel>

    <AliceCarousel
      mouseTracking
      items={items}
      disableButtonsControls
      autoPlay
      autoPlayStrategy="none"
      autoPlayInterval={3000}
      animationDuration={3000}
      animationType="fadeout"
      infinite
      className='slider-website-tablet'

    />

  </>)

}

export default Carousels;